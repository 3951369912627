*{
	font-family: $font-stack;
	color: $textDark;
	text-decoration: none !important;
	outline: none !important;
}


.Page_results{
	#Intro{
		display: none !important;
	}
}

body{
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #fff;
}

footer a{
	word-break: break-all;
}

/*** Scroll Bar ***/
::-webkit-scrollbar{
	width:8px;
	height:9px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0;
	background-color:transparent;
}
::-webkit-scrollbar-track-piece{
	background-color:#2b2b2b;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb{
	background-color: #6d7071;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb:vertical{
}
::-webkit-scrollbar-thumb:horizontal{
}
::-webkit-scrollbar-thumb:hover{
	background-color: #808182;
}
.CenterItems{
	display: flex;
	align-items: center;
	justify-content: center;
} 
.flex{
	display: flex;
}

.LikesCount{
	color: #00c2f2 !important;
}

.ActiveSubscribe{
overflow: hidden !important;
	.Form1{
		display: flex;
	}
}
.ActiveAdmissions{
overflow: hidden !important;
	.Form2{
		display: flex;
	}
}
.Formcontainer{
	position: relative;
	overflow: auto;
    max-height: 90vh;

}


.iframe{
	border: none !important;
    box-sizing: border-box;
    background-color: #fff;
    display: block;
    padding: 0px 10px;
    min-height: 400px;
}

.Form2{
	.Formcontainer{
	    width: 90%;
	}
	.iframe{
		width: 100%;
		min-height: 90vh;
	}
}

.ExternalForm{
	overflow-y: auto !important;
}

.ValueWord{
    position: absolute;
    bottom: 30px;
    left: 30px;
    z-index: 700;
    font-size: 3em;
    .part_1{
    	color: #2f3b67;
    	font-style: italic;
    	font-weight: bold;
    	display: block;
    	width: 100%;
    	float: left;
    	clear: both;
    	@include text-shadow(0px 0px 15px #fff);
    }
    .part_2{
    	@include text-shadow(0px 0px 15px #fff);
	    display: block;
	    width: 100%;
	    float: left;
	    clear: both;
    	color: #004d90;
    	font-weight: lighter;
    	font-size: 0.7em;
    	padding-left: 40px;
    	text-transform: uppercase; 
    }
}

/*** Staff, Vacancy and Newsletters start ***/
.noticesBG{
	opacity: 0;
	position: fixed;
	/*display: none;*/
	top: 0px;
	left: 0px;
	z-index: 9999;
	height: 100%;
	width: 100%;
	@include transition(opacity 800ms 500ms);
	background-color: rgba(240, 242, 247, 0.9);
}
.noticesContainer{
	opacity: 0;
	position: fixed;
	z-index: 9999;
	top: 50%;
	/*display: none;*/
	left: 50%;
	@include transform($center);
	/*padding: 30px;*/
	/*background-color: #060505;
	max-width: 100%;
	width: 500px;
*/
	@include transition(opacity 800ms 500ms);
	width: 100%;
	background-color: #ffffff;
    box-shadow: 0px 1px 2px 0px #e0e2ea;
    border: 1px solid #daddea;
    padding: 20px;
    @inlclude border-radius(3px);

    height: 500px;
    width: 500px;
    @include border-radius(50%);
    border: 2px solid #004d90;
}
.activeNotices{
	.noticesBG, .noticesContainer{
		opacity: 1 !important;
	}
}

.NoticeTitle{
	text-transform: uppercase;
	font-weight: bold;
	padding: 0px !important;
    border-bottom: none !important;
    font-size: 1.3em;
    color: #004d90 !important;
    text-align: center;
}
.readMoreLink{
    font-size: 0.8em;
    padding: 3px 5px;
    margin-top: 10px;

 	display: inline-block;
    padding: 6px 20px;
    color: #fff !important;
    background-color: #004d90;
    border: none !important;
    color: #fff;
    border-radius: 3px;
    background-color: #004d90;
    font-weight: lighter;
    margin-top: 10px;
    box-shadow :inset 0px -2px 8px 0px #23a3d6, inset 0px 2px 8px 0px #8bc2d8, 0px 0px 3px 1px #daddea;
   	-o-box-shadow :inset 0px -2px 8px 0px #23a3d6, inset 0px 2px 8px 0px #8bc2d8, 0px 0px 3px 1px #daddea;
    -ms-box-shadow :inset 0px -2px 8px 0px #23a3d6, inset 0px 2px 8px 0px #8bc2d8, 0px 0px 3px 1px #daddea;
    -moz-box-shadow :inset 0px -2px 8px 0px #23a3d6, inset 0px 2px 8px 0px #8bc2d8, 0px 0px 3px 1px #daddea;
    -webkit-box-shadow :inset 0px -2px 8px 0px #23a3d6, inset 0px 2px 8px 0px #8bc2d8, 0px 0px 2px 1px #daddea;
}
.fullWidth{
	position: relative;
	clear: both;
	width: 100%;
	float: left;
	text-align: center;
	padding: 10px;
}
.closeNotices{
	position: absolute;
    bottom: 20px;
    background-color: #004d90;
    padding: 5px 20px;
    left: 50%;
    padding-right: 20px;
    color: #fff;
    font-size: 0.8em;
    cursor: pointer;
    z-index: 9999;
    @include transform(translateX(-50%));

    display: inline-block;
    padding: 6px 20px;
    color: #fff !important;
    background-color: #004d90;
    border: none !important;
    color: #fff;
    border-radius: 3px;
    background-color: #004d90;
    font-weight: lighter;
    margin-top: 10px;
    box-shadow :inset 0px -2px 8px 0px #23a3d6, inset 0px 2px 8px 0px #8bc2d8, 0px 0px 3px 1px #daddea;
   	-o-box-shadow :inset 0px -2px 8px 0px #23a3d6, inset 0px 2px 8px 0px #8bc2d8, 0px 0px 3px 1px #daddea;
    -ms-box-shadow :inset 0px -2px 8px 0px #23a3d6, inset 0px 2px 8px 0px #8bc2d8, 0px 0px 3px 1px #daddea;
    -moz-box-shadow :inset 0px -2px 8px 0px #23a3d6, inset 0px 2px 8px 0px #8bc2d8, 0px 0px 3px 1px #daddea;
    -webkit-box-shadow :inset 0px -2px 8px 0px #23a3d6, inset 0px 2px 8px 0px #8bc2d8, 0px 0px 2px 1px #daddea;
}
/*.closeNotices:after {
    content: "x";
    position: absolute;
    color: #666;
    display: block;
    right: 5px;
    top: 49%;
    text-transform: uppercase;
    @include transform(translateY(-50%));
}*/
.noticesInner{
	max-height: 90vh;
    overflow-y: auto;
    padding: 10px;

    position: absolute;
    top: 50%;
    left: 50%;
    @include transform($center);
    width: 73%;
    height: 58%;

	*{
		color: #666;
		font-weight: lighter;
	}
	.col-12{
		/*
		border-bottom: 2px solid #eee;*/
	}

}

.error {
  display: inline-block;
  border: 1px solid #da8486;
  margin: 0px 0px 10px 0px;
  padding: 5px 20px;
  font-size: 1em;
  color: #da8486;
  background-color: #ffdfdf;
}

.FormContainer,
#content{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff;
		    background-color: #004d90;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transform($t1);
		    &:hover{
		    	background-color: #2F3B67 !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #009e7f;
		}
	}
}

#mapInner{
	height: 400px;
    border-bottom: 6px solid #919f76;
}

.col-6.brOrange{
	width: 100%;
	border-right: 0px !important;
	margin-bottom: 50px;
}
.col-6.eventContainer{
	display: none !important;
}
.HomePage .col-6.eventContainer{
	display: block !important;
}
.eventHeader{
	color: #2F3B67 !important;
}

.keyDates{
    padding-left: 25px;
    text-transform: uppercase;
    font-size: 1.3em;
}

/*#event-calendar-events .vevent {
    padding: 0 0 1em;
    margin: 0 0 1em;
    border-bottom: 2px solid #eee;
}*/

/*.calendar-widget-table tbody .calendar-day {

    border: 1px solid #c1c1c1 !important;
}
.calendar-widget-table tbody .calendar-day:hover, .calendar-widget tbody .show-week:hover, .calendar-widget tbody .selected {
    background-color: #cecece !important;
    color: #fff !important;
}
.calendar-widget-table tbody .calendar-header td {
    background-color: #585858 !important;
}

.calendar-widget-table thead th {
    background-color: #3a475e !important;
}

.calendar-widget-table tbody .calendar-day:hover, .calendar-widget tbody .show-week:hover, .calendar-widget tbody .selected {
    background-color: #cecece !important;
    color: #fff !important;
}
*/
.vevent .dates {
   font-weight: 200; 
}


#event-calendar-events .url{
    font-size: 1em;
    font-weight: lighter;
    color: #2f3b67;
}

.eventHeader{
    font-weight: lighter;
    text-transform: uppercase;
    font-size: 2em;
    margin-top: 20px;
}

.SubDate {
    font-weight: lighter;
    text-transform: uppercase;
    font-size: 1.5em;    
    color: #004d90;
}

/*
|===============================================================
|	Gallery 3
|===============================================================
*/

#bx_slider {
	height: 500px;
	li {
		height: 100%;
		text-align: center;
		img {
			display:inline-block;
			height: 100%;
		}
	}
}
.innerSlideGal{
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.GalNext,
.GalPrev{
	cursor: pointer;
	position: absolute;
	z-index: 200;
	top: 50%;
	margin-top: 8px;
	@include transform(translateY(-50%));
	i{
		color: #fff;
	}
}
.GalNext{
	left: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}
.GalPrev{
	right: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}

.bx_pages_frame {
	position: relative;
	#bx_pages {
		margin-top: 20px;
		li {
			background-position: center center;
			cursor: pointer;
			height: 200px;
		}
	}
	.controls {
		i {
			position: absolute;
			top: 50%;
			color: $mainColor2;
			font-size: 24px;
			margin-top: -12px;
			cursor: pointer;
			@include transition(all 0.5s);
			&:hover {
				opacity: 0.5;
			}
		}
		i.prev {
			left: 0;
		}
		i.next {
			right: 0;
		}
	}
}

/*
|===============================================================
|	Masonry
|===============================================================
*/

.grid {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 700px;
	a {
		font-size: 0;
		line-height: 0;
	}
}

.grid-item, .grid-sizer { 
	position: relative;
	width: 25%; 
	float: left;
	padding: 2px;
	cursor: pointer;
	@include transition(all 0.5s);
	img {
		width: 100%;
		height: auto;
	}
}

.grid-item.hoverState {
	opacity: 0.7;
}

.grid-item--width2 { width: 400px; }


.galleryImage{
	display: inline-block;
	width: 33.33%;
	min-width: 180px;
	min-height: 180px;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}

.archives{
	position: relative;
	display: block;
	float: left;
	width: 100%;
	clear: both;
	padding: 0px !important;
	margin: 0px 0px 30px 0px !important;

	.archive{
		position: relative;
		display: block;
		float: left;
		clear: both;
		color: #fff;
		width: 100%;
		margin: 5px 0px;
		background-color: #A1A1A3;
		@include transition($t1);
		img{
			position: absolute;
		    right: 10px;
		    top: 50%;
		    max-width: 17px;
			@include transform(translateY(-50%));
		}
		a{
			display: block;
			width: 100%;
			clear: both;
			float: left;
			padding: 8px 30px  8px 15px;
			top: 0px;
			left: 0px;
			width: 100%;
			z-index: 1;
			height: 100%;
		}
		*{
			color: #fff;
		}
		&:hover{
			background-color: $themeColor;
		}
	}
}
.veventContainer{
	margin-top: 40px !important; 
}

#event-calendar-events .vevent a.readMore {
    color: #fff;
     i{
     	color: #fff;
     }
}
.veventContainer .vevent,
.vacancies .vacancy{
	float: left;
	width: 100%;
	clear: both;
	border-bottom: 2px solid #eee !important;
	padding: 30px 0px;
}

.veventContainer .vevent:last-of-type,
.vacancies .vacancy:last-of-type{
	border-bottom: none !important;
}



#event-calendar-events{
	li{
		list-style: none !important;
		border-bottom: 2px solid #eee !important;
	}
}

.news-teaser{
	padding: 30px 0px;

	border-bottom: 2px solid #eee;
    &:last-of-type{
    	border-bottom: none !important;
    }
	img{
		@include border-radius(50%);
	}
	a h3 {
	    color: #004d90 !important;
	    font-size: 1.3em !important;
	    font-weight: lighter;
	    text-transform: uppercase;
	}
}


.button{
	display: inline-block;
    padding: 6px 20px;
    color: #fff !important;
    background-color: #004d90;
    i,
    span{
    	color: #fff !important;
    }
    @include transition($t1);
	&:hover{
		background-color: #2f3b67 !important;
	}
}

.upload{
	position: relative;
	display: block;
	width: 100%;
	max-width: 500px;
	clear: both;
	float: left;
	padding: 10px 25px 10px 30px;
	border: 1px solid $themeColor;
	margin: 5px 0px;
	background-color: transparent;
	@include transition($t1);
	.uploadTitle{
		text-transform: uppercase;
		font-size: 1em;
	    display: inline-block;
	    padding-top: 5px;
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.fileIcon{
	    background-color:$themeColor;
	    display: inline-block;
	    float: right;
	    width: 25px;
	    height: 30px;
	    @include transition($t1);
	    span{
	    	@include transition($t1);
	    	color: #fff;
		    background-color: #102d40;
		    height: 13px;
		    width: 100%;
		    padding-top: 2px;
		    font-size: 7px;
		    text-transform: uppercase;
		    display: block;
		    text-align: center;
		    margin-top: 8px;
	    }
	}

	&:hover{
		background-color: #eee  !important;
		color: #000 !important;
		.uploadTitle{
			/*color: #000 !important;*/
		}
		/*.fileIcon{
	    	background-color: #000 !important;
			span{
			    color: #000 !important;
				background-color: #009e7f;
		   }
		}*/
	}
}


.upload{
	/*padding: 10px;
	border-bottom: 2px solid #eee;*/
	*{
		vertical-align: middle;
		font-weight: lighter !important;
	}
}



.profilePic{
    width: 150px;
    float: none;
    margin: 20px 0px 30px 0px;
    min-width: 150px;
    height: 150px;
    background-repeat: no-repeat;
    border-radius: 50%;
    border: 1px solid rgba(208,189,211,0.49);
    background-size: cover;
    background-position: center;
    background-color: #E5E9EA;
    display: inline-block;
    box-shadow: 0px 0px 0px 9px #fff, 0px 0px 0px 10px rgba(169, 135, 176, 0.31);
	-o-box-shadow: 0px 0px 0px 9px #fff, 0px 0px 0px 10px rgba(169, 135, 176, 0.31);
	-ms-box-shadow: 0px 0px 0px 9px #fff, 0px 0px 0px 10px rgba(169, 135, 176, 0.31);
	-moz-box-shadow: 0px 0px 0px 9px #fff, 0px 0px 0px 10px rgba(169, 135, 176, 0.31);
	-webkit-box-shadow: 0px 0px 0px 9px #fff, 0px 0px 0px 10px rgba(169, 135, 176, 0.31);
}

.staff-title{
	position: relative;
}


.Newsletter{
	width: 33.33%;
    padding-bottom: 33.33%;
    position: relative;
	min-width: 200px;
    min-height: 200px;
    display: inline-block;
    float: none;
}
.NewsLetterInner{
	top: 50%;
	left: 50%;
	width: 90%;
	height: 90%;
	border-radius: 3px;
	overflow: hidden;
	position: absolute;
	border: 2px solid #e7e7e7;
	background-color: #efefef;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
}



.NewsLetterInner .imgContainer{
	display: inline-block;
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}
.Newsletter a{
	position: absolute; 
	width: 100%;
	height: 100%;
	z-index: 200;
	left: 0px;
    top: 0px;
}
.Newsletter:hover .NewsLetterInner{
	background-color: #f5f5f5;
	border: 2px solid #e7e7e7;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-ms-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
}

.Newsletter .downloadInfo{
	position: absolute;
	transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	left: 50%;
	color: #777;
	width: 98%;
	text-align: center;
	font-size: 1em;
	bottom: 10%;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
}

.Newsletter:hover .downloadInfo{
	color: #2F3B67 !important;
}

.NewsletterTitle{
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 30px;
	margin-top: 50px;
	text-align: center;
	font-size: 1.5em;
	font-weight: lighter;
	color: #2F3B67 !important;
	font-weight: lighter;
	*{
		font-weight: lighter;
		color: #2F3B67 !important;
	}
}


.staff-title{
    display: inline-block;
}

.staff-title span{
    padding: 0px 5px;
    display: block;
    position: relative;
    font-weight: lighter;
}

.profilePic:after{
	content: '';
    border: 1px solid rgba(169, 135, 176, 0.31);
    border-top: none;
    border-left: none;
    background-color: #fff;
    display: block;
    height: 10px;
    width: 10px;
    position: absolute;
    left: 50%;
    bottom: -20px;
    transform: rotate(45deg) translateX(-50%);
    -o-transform: rotate(45deg) translateX(-50%);
    -ms-transform: rotate(45deg) translateX(-50%);
    -moz-transform: rotate(45deg) translateX(-50%);
    -webkit-transform: rotate(45deg) translateX(-50%);
}

.department {
    margin-bottom: 30px;
    border-bottom: 2px solid #eee;
}
.department:last-of-type{
	border-bottom:none !important
}

.bioSection{
	margin-top: 12px;
    color: #000 !important;
    width: 100%;
    font-size: 1.1em !important;
}

.staffProfile:hover .bioSection{
	opacity: 1;
}

.bioSection p{
	color: #364650 !important;
}

.staffProfile{
	display: inline-block;
    padding: 20px;
    width: 240px;
    text-align: center;
	vertical-align: top;
}

.name {
    color: #2f3b67;
    font-size: 1.4em;
    font-weight: 600;
}

.bio{
    font-size: 0.9em;
}
.staff-title{
	width: 100%;
}
.staff-title span {
    /* font-weight: 100 !important; */
    color: #364650 !important;
    font-weight: lighter !important;
    text-transform: uppercase;
}



.vacancy{
    padding: 30px 0px;
    border-bottom: 2px solid #2d2d2d;
}

.removalDate{
	margin-bottom: 0px !important;
	font-weight: lighter;
	color: #000000 !important;
}

.heading-2{
	font-weight: lighter;
    color: $themeColor !important;
	*{
		font-weight: lighter;
    	color: #fff !important;
	}
	.date{
		font-size: 0.7em;
		color: #004d90 !important
	}
}

/*** Staff, Vacancy and Newsletters end ***/

.welcomeContainer{
	background-color: #ccdbe9;
	position: fixed;
	z-index: 9999;
	width: 43%;
	opacity: 0;
	left: -100%;
	max-height: 100%;
	overflow-y: auto;
	@include transition(all 1s);
	height: 100%;
    .welcomeInner{
    	position: relative;
    	padding: 20px;
    	max-width: 72%;
    	margin: 0 auto;
    	.welcome-fly1,
    	.welcome-fly2{
    		position: absolute;
    	}
    	.welcome-fly1{
    		right: -27px;
    		top: -95px;
    	}
    	.welcome-fly2{
    		right: 107px;
   		 	top: -113px;
    	}
    	*{
    		color: #2f3b67;
    	}
    	.innerText{
		    font-weight: lighter;
		    font-size: 0.9em;
		}
		.Title{
		    font-weight: lighter;
		    font-size: 2.5em;
		    text-transform: uppercase;
		}
    }
}
#Home1{
	.col-five{
		.center{
			padding-bottom: 100%;
			overflow: hidden;
			a{position: absolute; top: 0px;left: 0px;width: 100%; height: 100%;}
			.bg{
				position:absolute;
				left: 0px;
				width: 100%;
				height: 100%;
				top: 0px;
				background-image: url(../images/ISimg19.jpg);
				background-size: cover;
				background-position: center;
				@include transform(scale(1));
				@include transition($t1);
			}
			.bg-1 {
				background-color: rgba(#074c87, 0.8);
			}
			.bg-2, .bg-4{ 
				background-color: rgba(#004d90, 0.6);
			}
			.bg-3, .bg-0{ 
				background-color: rgba(#282443, 0.9);
			}
			.hover{
				position: absolute;
				bottom: 0px;
				left: 0px;
				width: 100%;
				min-height: 0%;
				padding: 20px 20px;
				@include transition(all 1s);
				*{color: #fff;vertical-align: middle;display: inline-block;font-weight: lighter;font-size: 1.2em;}
				img{
					float: right;
					width: 40px;
					height: auto;
				}
			}
			&:hover{
				.bg{
					@include transform(scale(1.1));
				}
				.hover{
					min-height: 100%;
				}
			}
		}
	}
}

.LinkboxPadding{
	padding: 0px 5px 0px 5px;
	z-index: 7;
}
.HomePage #Home1{
	padding: 20px 0px;
	.LinkboxPadding{
		padding: 5px 5px 5px 5px !important;
	}
}


.fly5 {
    position: absolute;
    top: 70px;
    right: 30%;
    opacity: 0.2;
    display: none;
}

.calendar-widget-table{
 /*   margin: 0 auto !important;*/
}
.calendar-widget-table tbody td {
    background-color: transparent !important;
}

.calendar-widget-table thead th {
	background-color: transparent !important;

 a{
    color: #2f3b67 !important;
    font-weight: lighter;
    text-transform: uppercase;
 }
}
.calendar-widget-table tbody .calendar-header td{
	background-color: transparent;
	color: #2f3b67 !important;
	padding: 20px 0;
	text-transform: uppercase;
	font-size: 1em !important;
}

.calendar-widget-table  td{
	font-size: 1.3em !important;
    font-weight: lighter !important;
}
.calendar-widget-table table td, .calendar-widget table th{
	border: none !important;
	border-bottom: 10px solid #eee !important;
}
.calendar-day{
	color: #2f3b67;
}
.calendar-widget-table tbody .calendar-day:hover, .calendar-widget tbody .show-week:hover, .calendar-widget tbody .selected{
	background-color: #2f3b67 !important;
	 color: #fff !important;
}
.calendar-widget-table tbody .calendar-day{
	border: none !important;
}
.calendar-widget-table tbody .out-of-month {
    background-color: transparent !important;
}
.calendarLeft{
    padding-right: 11%;
}
.show-month{
    text-align: left;
    float: left;
}

.calendar-widget-table .prev{
    position: absolute;
    right: 41px;   
    background-position: 42% 50%;
	background-image: url(../images/arrow2.png);
}
.calendar-widget-table .next{
    position: absolute;
    right: 0px;
    background-position: 60% 50%;
    background-image: url(../images/arrow.png);
}
.calendar-widget-table .prev,
.calendar-widget-table .next{
	display: block;
	margin-top: 7px;
	width: 25px;
    height: 25px;
	background-color: #b6b6b7;
	@include border-radius(50%);
    background-repeat: no-repeat;
    @include transition($t1);
	&:hover{
		background-color: #2f3b67 !important;
	}
}

.coverLink{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}


#sliderEvents{
	padding: 0px !important;
}

#sliderEvents section{
	float: left;
	clear: both;
}

.clear{
	clear: both;
}

.innerDiv{
	padding-bottom: 10px;
	
	.slideOverlay{
		position: absolute;
		top: 0px;
		right: -100%;
		width: 100%;
		height: 100%;
		@include transition(all 600ms);
		background-color: #8ac9ee;
		color: #fff !important;
		font-size: 1.3em;
		text-transform: uppercase;
		font-weight: lighter;
		*{color: #fff !important;}
		img{
			margin-bottom: 10px;
		}
	}
	&:hover{
		.slideOverlay{
			right: 0%;
		}
	}
}
.sliderContent{
	background-color: #2F3B67;
	@include border-radius(10px);
	overflow: hidden;

	color: #fff;
	.col-8,
	.col-4{
		padding: 20px;
	}
	.col-4{
		border-right: 1px solid #fff;
	}
	.slideTitle{
		color: #8ac9ee;
		font-weight: lighter; 
	}
	.slideContentText{
	    color: #fff;
	    margin-top: 10px;
	    font-weight: lighter;
	}
}



.SliderControls{
	text-align: center;
	.next,
	.calendarLink{
		display: inline-block;
		position: relative;
		span{
			color: #6b626b;
			font-size: 1em !important;
		}
	}
	*{
		font-weight: lighter;
	}
	.calendarLink{
			font-size: 1em !important;
		span{
			display: inline-block;
			vertical-align: middle;
			font-size: 1em !important;
		}
		img{
			width: 27px;
			display: inline-block;
			vertical-align: middle;
			margin-left: 10px;
		}
	}
	.next{
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			z-index: 1;
			font-size: 1em !important;
			*{font-size: 1em !important;}
		}
		span, img{
			display: inline-block;
			vertical-align: middle;
		}
		img{

			margin-left: 10px;
		}
	}
	.nextLink{
			font-size: 1em !important;
			*{font-size: 1em !important;}
	}
}

.Link{
	display: inline-block;
	position: relative;
	font-size: 1em !important;
	span{
		display: inline-block;
		vertical-align: middle;
		color: #6b626b;
		font-size: 1em !important;
		@include transition($t1);
	}
	img{ 
		width: 27px;
		display: inline-block;
		vertical-align: middle;
		margin-left: 10px;
	}
	&:hover{
		span{
			color: #17adec !important;
		}
	}
}


.datePart1{
	display: block;
	width: 100%;
	text-align: center;
	color: #8ac9ee;
	font-size: 3em;
}
.datePart2{
	display: block;
	width: 100%;
	text-align: center;
	color: #8ac9ee;
	text-transform: uppercase;
}
#SliderControls{
	text-align: right;
}
.prev a,
.next a{
	text-decoration: none !important;
	color: #999;
	text-transform: uppercase;
	text-align: center;
}

.pageTitle{
	font-weight: lighter;
	font-size: 4em;
	text-transform: uppercase;
	color: #2F3B67;
}

.closeWelcome{
	cursor: pointer;
    background-color: #2F3B67;
    display: inline-block;
    padding: 6px 15px;
    color: #fff !important;
    border-radius: 5px;
    font-weight: lighter;
    font-size: 0.85em;
}
.ActiveWelcome{
	overflow: hidden !important;
	.welcomeContainer{
		opacity: 1 !important;
		left: 0% !important;
	}
}
img{
	max-width: 100%;
}

.topLink{
	position: fixed;
    top: 0px;
    height: 34px;
    width: 34px;
    z-index: 9999;
}
.searchToggle{
	right: 59px;
    background-color: #004d90;
}
.translate {
    background-color: #004d90;
    right: 20px;
    img{
    	max-width: 24px;
	    position: absolute;
	    left: 50%;
	    @include transform(translateX(-50%));
	    top: 4px;
    }
}
.searchToggleButton{
	position: absolute;
	left: 50%;
	top: 7px;
	@include transform(translateX(-50%));
}

.welcomeToggle{
	position: absolute;
	background-color: #2f3b67;
	padding: 10px 20px;
	color: #fff !important;
	@include border-radius(0px 0px 10px 10px);
	@include transform(rotate(-90deg));
	left: -80px;
	cursor: pointer;
	top: 50%;
	margin-top: -23.5px;
	font-size: 1.4em;
	z-index: 40;
}
.QuickLinksToggle{
	position: absolute;
	background-color: #2f3b67;
	padding: 10px 20px;
	color: #fff !important;
	@include border-radius(  0px 0px 10px 10px);
	@include transform(rotate(90deg));
	right: -55px;
	cursor: pointer;
	top: 27%;
	margin-top: -23.5px;
	font-size: 1.4em;
	z-index: 40;
}

.closeQuicklinks{
	padding: 20px;
	color: #fff;
	background-color: #29345a;
	position: relative;
	cursor: pointer;
	display: none;
	width: 100%;
	clear: both;
	float: left;
	text-align: center;
	text-transform: uppercase;
}

#QuickLinkContainer{
	position: fixed;
	top: 0px;
	right: -25%;
	width: 25%;
    z-index: 9999;
	height: 100vh;
	background-color: #2b2b2b;
	@include transition(all 800ms);
	/*transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    perspective: 100px;
    -webkit-perspective: 100px;*/

    .quickLinksInner {
    	position: relative;
	    width: 100%;
	    height: 100vh;
	    overflow: hidden;
		overflow-y: auto;
	    left: 0;
	    float: left;
	    color: #fff;
	    @include transition(all 800ms);
	    @include transform(perspective(200px) rotateY(45deg));
	    transform-origin: 0% 50%;
	    -webkit-transform-origin: 0% 50%;
	    background-color: #eee;
	}
}

.innerContent{
	margin: 0 auto;
	/* max-width: 700px; */
	max-width: 1000px;
	width: 100%;
	position: relative;
	padding-top: 30px;
	clear: both;
}

.StaffPage .innerContent {
    max-width: 760px;
}

.quicklink{
	height: 20vh;
	padding: 20px 20px;
	min-height: 135px;
	span{
		display: inline-block;
		text-align: center;
		padding: 10px 0px 8px 0px;
		color: #fff;
		font-size: 1.3em;
		position: relative;
		&:before{
			content:'';
			position: absolute;
			left: 50%;
			bottom: 0px;
			display: block;
			height: 1px;
			@include transform(translateX(-50%));
			width: 0%;
			opacity: 0;
			background-color: #2f3b67;
			@include transition($t1);
		}
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	img{
		@include transition($t1);
		opacity: 0;
		width: 0px;
	}
	&:hover{
		img{
			opacity: 1 !important;
			width: 50px !important;
		}
		span{
			&:before{
				opacity: 1 !important;
				width: 100% !important;
			}
		}
	}
}

.bgColor-1{
	background-color: #2f3b67;
	span{
		&:before{
			background-color: #8ac9ee !important;
		}
	}
}

.bgColor-2{
	background-color: #17aae1;
}

.bgColor-3{
	background-color: #8ac9ee;
}


.bgColor-4{
	background-color: #6dacd1;
}

.bgColor-0{
	background-color: #2f3b67;
	span{
		&:before{
			background-color: #8ac9ee !important;
		}
	}
}



.ActiveQuickLinks{
	.PageContainer,
	.banner{
		min-width: 75% !important;
		width: 75% !important;
	}
	#QuickLinkContainer{
		right: 0% !important;
		.quickLinksInner {
	   		@include transform(perspective(200px) rotateY(0deg) !important);
		}
	} 
}


.sideLinks {
    position: absolute;
    right: 0px;
    bottom: 13%;

	z-index: 9001;


	/**/
	.sideLink{
		background-color: #fff;
		clear: both;
		margin: 3px 0px;
		@include border-radius(30px 0px 0px 30px);
		display: block;
		float: right;
		padding: 5px 0px 5px 40px;
		@include transition($t1);
		position: relative;

		.sideLinkLeft{
			text-align: center;
    		width: 27px;
			position: absolute;
			left: 10px;
			top: 50%;
			display: block;
			@include transform(translateY(-50%));
			display: inline-block;
			
			img{
				display: inline-block;
				height: 20px;
			}
		}
		.sideLinkRight{
			display: inline-block;
			@include transition($t1);
			overflow: hidden;
			color: #fff;
			white-space: nowrap;
			font-weight: lighter;
		}
		&:hover{
			padding: 5px 20px 5px 50px;
			background-color: #2f3b67 !important;
		}
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			display: block;
			z-index: 1;
		}
	}
	
	/**/

/*	*{
		vertical-align: middle;
	}
	text-align: right
	a{
		background-color: #fff;
		clear: both;
		margin: 3px 0px;
		@include border-radius(30px 0px 0px 30px);
		display: inline-block;
		img{
			display: inline-block;
			height: 30px;
			width: auto;
			float: left;
		}
		span{
			@include transition($t1);
			display: block;
			float: left;
			overflow: hidden;
			color: #2f3b67;
			width: 0px;
		}
		
	}*/
}

/*** Menu ***/
#Menu{
	z-index: 8880;
	right: 115px;
	position: fixed;
	top: 0px;
	display: inline-block;
	text-align: right;
	*{
		color: #fff;
		font-weight: lighter;
	}
	.MenuButton{
		display: inline-block;
		position: absolute;
		z-index: 2;
		cursor: pointer;
		background-color: #004d90;
	    right: 0px;
	    padding: 8px 12px 7px 12px;
	    cursor: pointer;
	    opacity: 1;
	    top: 0px;
	    text-transform: uppercase;
	}
	.MenuContainer{
		position: relative;
		z-index: -9999;
		margin-top: -600px;
		.LevelOne{
			display: inline-block;
			text-align: center;
			position: relative;
			a {
				padding: 10px;
				text-transform: uppercase;
				font-size: 0.7em;
				line-height: 3em;
			}
		}
	}
	.SubMenu{
		@include box-shadow(-2px 13px 40px 0px rgba(47, 47, 47, 0.33));
		width: 250px;
		height: 0px;
		overflow: hidden;
		display: block;
		z-index: -9999;
    	left: 50%;
    	text-align: left;
		position: absolute;
		background-color: #2f3b67;
	    border-radius: 5px;
	    padding: 3px 10px 6px 10px;
	    margin-top: 16px;
	    opacity: 0;
	    transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -o-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -ms-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -moz-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -webkit-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    @include transform(translateY(20px) translateX(-50%));

	    a{
		    display: inline-block;
		    padding: 3px 10px !important;
		    margin: 3px 0px;
		    /*font-size: 0.9em;*/
		    line-height: 1.2em !important;
		    width: 100%;
			@include border-radius(3px);
			background-color: transparent;
	    	@include transition($t1);
			&:hover{
    			background-color: rgba(23, 170, 228, 0.25) !important;
			}
		}
	}

	.active .SubMenu{
		opacity: 1 !important;
	 	@include transform(translateY(0px) translateX(-50%) !important);
	}

	.SubMenu:before {
	    content: '';
	    display: block;
	    position: absolute;
	    width: 100%;
	    height: 17px;
	    left: 0px;
	    top: -14px;
	    z-index: 2;
	}
	.SubMenu:after {
	    content: '';
	    display: block;
	    position: absolute;
	    border: 10px solid transparent;
	    top: -20px;
	    left: 50%;
	    margin-left: -10px;
	    border-bottom: 10px solid #2f3b67;
	}
}
.LevelOne a:after {
    content: '';
    position: absolute;
    height: 15px;
    width: 1px;
    background-color: #2f3b67;
    top: 49%;
    right: 0px;
    @include transform(translateY(-50%));
}
.LevelOne a{
	@include transition($t1);
}
.LevelOne.active a.topMenuLink{
	color: #2aaae2 !important;
}
.LevelOne:last-of-type a:after{
	display: none !important;
}
.menuGroup{
	opacity: 0;
	margin-right: -20px;
}
.MainMenuClose{
	display: inline-block;
}

.topBar{
	top: 0px;
	height: 34px;
	width: 0%;
	right: 0px;
	z-index: 8000;
	position: fixed;
	background-color: #004d90;
}
/* .ActiveMenu{ */
	.topBar{
		width: 100% !important;
	/*	opacity: 0;*/
	}
	.menuGroup{
		opacity: 1 !important;
		margin-right: 0px !important;
	}
	
	.MenuButton{
		top: -50px !important;
		opacity: 0 !important;
	}
	.MenuContainer{
		z-index: 1 !important;
		margin-top: 0px !important;
	}
/* } */
/*** Menu ***/

.waves{
	position: absolute;
	/*bottom: -5%;*/
	bottom: -22%;
	width: 100%;
	left: 0px;
	z-index: 1;

	.wave{
	    position: relative;
	    width: 100%;
	    float: left;
	}
	.waveInner{
	    overflow: hidden;
	    width: 00%;
	}
	img{
	    width: 100%;
	    max-width: none;
	}
	.mb{
		margin-bottom: -5.5%;
	}
}



.topGrad{
	position: absolute;
	width: 100%;
	top: 0px;
	left: 0px;
	height: 30%;
	opacity: 0.9;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100;White+to+Transparent */
	background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
	display: none !important;
}
.bottomGrad{
	position: absolute;
	width: 100%;
	bottom: 0px;
	left: 0px;
	height: 30%;
	z-index: 2;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
	background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
	z-index: 2;
	/*opacity: 0.9;*/
}

.Group1{
	opacity: 0;
}

.bannerInfo.Group1{
	@include transform(translateY(20px));
}

.AnimatePage{
	.waveInner{
		width: 100%;
	}
	.Group1{
		opacity: 1 !important;
	}
	.bannerInfo{
		@include transform(translateY(0px) !important);
	}
}
.tBlue1{
	color: #fff;
}
.tBlue2{
	color: #004d90;
}

.bannerInfo{
	z-index: 3;
	position: absolute;
	text-align: center;
	width: 100%;
	left: 0px;
	bottom: 60px;
	bottom: 215px;

	a{
		margin: 5px;
		display: inline-block;
		min-width: 250px;
		padding: 10px 20px;
		@include border-radius(10px);
		color: #fff !important;
		font-weight: lighter;
		@include transform(translateY(0px));
		@include transition($t1);
		&:hover{
			@include transform(translateY(-5px));
		}
	}
	.subscribe{
		background-color: #004d90;
	}
	.admissions{
		background-color: #595959;
	}
	img{
	    position: absolute;
	    top: -135px;
	    right: 15%;
	}
}

.bannerText{
	font-size: 2.7vw;
	margin-bottom: 30px;
	*{
		text-transform: uppercase;
		font-weight: lighter;
	}
}

#Intro{
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background-color: #fff;
	img {
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform($center);
	}
}

.mainLogo{
	opacity: 0;
}
.mainLogo1{
	opacity: 1;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 500px;
	height: auto;
}
.flybody{
	top: 50%;
	left: 50%;
	position: absolute;
	@include transform($center);
}
.wingleft{
	position: absolute;
    left: -13%;
    top: 6%;
    transform-origin: 100% 100%;
    -o-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    -moz-transform-origin: 100% 100%;
    -webkit-transform-origin: 100% 100%;
    @include transform(rotateY(51deg));
    animation: wingleft 1s linear 0s infinite alternate;
}
.wingright{
	position: absolute;
	right: -15%;
    top: 15%;
     transform-origin: 3% 0%;
    -o-transform-origin: 3% 0%;
    -ms-transform-origin: 3% 0%;
    -moz-transform-origin: 3% 0%;
    -webkit-transform-origin: 3% 0%;
     @include transform(rotateY(0deg));
    animation: wingright 1s linear 0s infinite alternate;
}

@keyframes wingleft {
    0%   {
    	@include transform(rotateY(0deg));
    }
    100% {
    	@include transform(rotateY(51deg));
    }
}

@keyframes wingright {
    0%   {
    	@include transform(rotateY(0deg));
    }
    100% {
    	@include transform(rotateY(51deg));
    }
}


#Logo{
	position: absolute;
	top: 45px;
	left: 40px;
	z-index: 6000;
	display: inline-block;
	/* max-width: 249px; */
    min-width: 120px;
    /*max-width: 330px;   */
    max-width: 128px;
}

/* Slider Styling */ 
#SliderSectionContainer{
	margin-bottom: 50px;
}
.SliderTitle {
    font-size: 1.5em;
    color: #EE363C;
    text-align: center;
    padding: 20px 0px 30px 0px;
}
.bx-controls{
	text-align: center;
}
.bx-pager-item{
	margin: 5px;
	width: 10px;
	height: 10px;
	opacity: 0.5;
	background-color: #2b2b2b;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
	border-radius: 50%;
	-o-border-radius: 50%;
	-ms-border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	display: inline-block;
}
.bx-pager-item:hover{
	opacity: 1 !important;
}
.bx-pager-item a{
	color: transparent;
}
/* Slider Styling */

/*** Video Page ***/
.videoHeader{
	height: 120px;
	background-color: #000;
}
.fullScreenBlackout{
	display: none;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	background-color: #000;
	height: 100%;
	z-index: 9998;
}
.videoContainer{
	padding-bottom: 25%;
	overflow: hidden;
}
.video_background{
	width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform($center);
}

.active.video_background{
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    z-index: 9999;
}
.active{
	.closeVideo{
		display: block !important;
	}
}

.videoBlackout{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0, 0.4);
}

.closeVideo{
	display: none;
	position: fixed;
	top: 10px;
	right: 10px;
	z-index: 9999;
	cursor: pointer;
	color: #fff;
	font-size: 1.8em;
	i{color: #fff;}
}

.watchFull{
	cursor: pointer;
	position: absolute;
	color: #fff;
	top: 50%;
	left: 50%;
	z-index: 5;
	@include transform($center);
}
.videoWrapper .videoContainer:nth-child(odd):last-of-type{
	padding-bottom: 50% !important;
	width: 100% !important;
}
@media all and (max-width: 860px)  {
	.videoContainer{
		padding-bottom: 50%;
		width: 100%;
	}
}
/*** Video Page ***/

.db{
	display: block;
}

fieldset{
	border: none;
	.field.checkbox {
		.middleColumn {
			display:inline-block;
		}
	}
}




/*** Smooth Scrolling ***/
.smooth {
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    background-color: $mainColor;
    @include perspective(1000);
    @include transform-origin(0 0);
	@include box-sizing(border-box);
    @include backface-visibility(hidden);
    @include transform(translate3d(0px, 0px, 0px));
    @include transition(all .5s cubic-bezier(0.2, .84, .5, 1));
}

.wrapper{
	position: relative;
	float: left;
	width: 100%;
	background-color: $mainColor;
}

.SmoothScroll{
	 .parallax1 {
		@include perspective(1000);
	    @include transform-origin(0 0);
		@include box-sizing(border-box);
	    @include backface-visibility(hidden);
	    @include transform(translate3d(0px, 0px, 0px));
	    @include transition(all .5s cubic-bezier(0.2, .84, .5, 1));
	}
}

.StandardScroll{
	.smooth{
		 position: relative;
	}
}

/*** Loading Bar ***/
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  z-index: 2000;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 5px;
  width: 200px;
  z-index: 9999;
  background: #fff;
  border: 1px solid #29d;
  overflow: hidden;
}

.pace .pace-progress {
	@include box-sizing(border-box);
	@include transform(translate3d(0, 0, 0));
	max-width: 200px;
	position: fixed;
	z-index: 2000;
	display: block;
	position: absolute;
	top: 0;
	right: 100%;
	height: 100%;
	width: 100%;
	background: #29d;
}

.pace.pace-inactive {
  display: none;
}

/*** Page Loading ***/
.Loading{
	height: 100vh;
	overflow: hidden;
}
.pace-running{
	overflow: hidden;
}

/*** Page Loading Complete ***/
.pace-done{
	.Loading{
		height: 0%;
		opacity: 0;
	}
}

#Home2{
	padding: 180px 20px 143px 20px;

	.innerSmall{
		opacity: 0;
		@include transition(all 1s 500ms);
	}
}

#Home2.ActiveIn{
	.innerSmall{
		opacity: 1 !important;
	}
}
#Home3{
	padding: 80px 20px;
	min-height: 100vh;
	background-image: url(../images/ISimg19.jpg);
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	-webkit-background-attachment: fixed;

	.inner{
		position: relative;
		z-index: 2;
		max-width: 600px;
	}

	.pageTitle{
		color: #fff !important;
		text-align: center;
		font-size: 4em;
		text-transform: uppercase;
		margin-bottom: 30px;
	}
	.newsContainer{
		background-color: #fff;
		margin: 6px;
	}
	.newsText{
		padding: 20px 20px 50px 20px;
	}
	.newsImage{
		background-image: url(../images/ISimg19.jpg);
		background-position: center;
		background-size: cover;
		padding-bottom: 33.33%;
		overflow: hidden;
		.newsHover{
			position: absolute;
			text-align: center;
			top: 50%;
			left: 50%;
			width: 102%;
			height: 102%;
			background-color: rgba(43,43,43,0.8);
			
			@include transform($center scale(0.7));
			opacity: 0;
			@include transition($t1);
			*{
				color: #fff;
				text-transform: uppercase;
			}
		}
	}
	section:hover{
		.newsImage{
			.newsHover{
				opacity: 1 !important;
				@include transform($center scale(1) !important);
			}
		}
	}
	.heading{
		color: #2f3b67 !important;
		margin-bottom: 30px;
		font-size: 1.2em;
		font-weight: lighter;
	}
	.newsContent{
		color: #666;
		font-weight: lighter;
	}

	.bottomSection{
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: 100%;
		background-color: #8ac9ee;
		color: #2f3b67;
		padding: 5px 20px;
		font-weight: lighter;
    	line-height: 1.5em;
		span,
		img{
			display: inline-block;
			vertical-align: middle;
		}
		span{
			margin-left: 10px;
		}
	}
	.SliderControls{
		*{
			color: #fff !important;
		}
	}
}

.fly1{
	position: absolute;
	bottom: -250px;
	left: -250px;
	 z-index: -1;
}
.fly2{
	position: absolute;
    top: 8px;
    right: 15%;
    opacity: 0.2;
}
.fly3{
	position: absolute;
	opacity: 0.2;
    top: -101px;
    right: -8%;
}

#Home4{
	background-color: #b4cade;
	padding: 140px 0px 80px 0px;
	overflow: hidden;
	.inner{
		max-width: 1100px;
		width: 100%;
	}
	.socialBox{
		padding: 20px 20px 50px 20px;
		background-color: #fff;
		.innerTitle {
			margin-bottom: 30px;
			i{
			    font-size: 2em;
			    color: #2f3b67 !important;
			    display: inline-block;
			    margin-right: 20px;
			    vertical-align: middle;
			}
			span{

				display: inline-block;
				color: #2f3b67 !important;
				vertical-align: middle;
				font-size: 1.1em;
				font-weight: lighter;
			}
		}
		.socialContent{
			color: #666 !important;
			font-weight: lighter;
		}
		.bottomContainer{
			position: absolute;
			left: 0px;
			bottom: 0px;
			width: 100%;
			padding: 5px 20px;
			background-color: #2f3b67;
			color: #fff;
			*{color: #fff;font-weight: lighter;}
			svg path{
				@include transition($t1);
			}
			svg:hover path{
				fill: #00c2f2 !important
			}
			.shareButtons{
				a{
					display: inline-block;
					vertical-align: middle;
					padding: 0px 3px
				}
			}
			.Link{
				img{
					width: 22px !important;
				}
			}
		}
	}
}
/*** HomePage ***/
.banner{
	position: relative;
	overflow: hidden;
	float: left;
	width: 100%;
	/* height: 70vh; */
	height: 44vw;
	min-height: 260px;
	@include transition(all 800ms);
/*	@include background-cover(url("../images/bg.jpg"));*/

	
	.flexslider .slides > li {
	    /* height: 70vh; */
	    height: 44vw;
	    min-height: 260px;
	}
	.slideBG{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		background-size: cover;
		background-position: center;
	}
	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		@include background-cover(url("../images/inner_banner.jpg"));
	}
}
.PageContainer{
	@include transition(all 800ms);
}

.PageBanner{
	.sideLinks {
		bottom: 40px;
   		top: auto;
   	 	@include transform(translateY(0%) !important);
	}
}

.HomeBanner{
	height: 100vh !important;
	.flexslider .slides > li {
	    height: 100vh !important;
	}
}

.darken{
	@include stretchCover(rgba(43,43,43, 0.5), absolute, 1)
}

#gmap_canvas{
	min-height: 400px;
}

#Home1{
	.col-five{
		opacity: 0;
		@include transform(scale(0.4));
		span{
		    padding-right: 52px;
		    width: 100%;
		}
		img{
			width: 50px;
			position: absolute;
			top: 50%;
			@include transform(translateY(-50%));
			right: 0px;

		}
	}
}

#Home1.ActiveIn .col-five{
	opacity: 1 !important;
	@include transform(scale(1) !important);
}


/*** Footer ***/
footer{
	border-bottom: #2f3b67 76px solid;
	padding: 30px 5%;
	.slink{
		border: 1px solid #666;
		padding: 10px;
		cursor: pointer;
		background-color: #fff;
		@include transition($t1);
		&:hover{
			background-color: #eee !important;
		}
	}
	.fse{
		font-weight: lighter;
		font-size: 0.8em;
		margin-top: 20px;
	}
	span{
		display: inline-block;
		vertical-align: middle;
		color: #666;
		font-weight: lighter;
	}
	.footerLogo{
		display: inline-block;
		padding-bottom: 20px;
		max-width: 100%;
		width: 250px;    
		width: 150px;
	}
	.divider{
		padding: 0px 5px;
		color: #2f3b67 !important;
	}
	.bottomFooter{
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: 100%;
	}
	.footerLabel{
		color: #2f3b67 !important;
	}
	.col-6:last-of-type{
		text-align: right;
	}
}
.sideBarContainer{
	background-color: #f6f6f6;
}


.SearchContainer{
    display: none;
    position: fixed;
    top: -9px;
    background-color: #2f3b67;
    padding: 7px;
    right: 69px;
    z-index: 9999;
	@include transform(translateY(100%))

	#Search{
		display: block;
		float: left;
	}

    .middleColumn,
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    button{
	    background-color: rgb(141, 164, 68);
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;


	    i{
	    	color: #fff !important;
	    }
	    float: left;
    	display: block;
    }
    input[type=submit]{
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 5px;
	    color: #fff !important;
	    font-style: italic;
	    background-color: #004d90;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px;
	    color: #5f5e6d;
	    float: left;
    	display: block;
    }
}

#breadcrumbs{
    background-color: #004d90;
    padding: 20px 5%;
    font-size: 1.2em;
    *{
    	font-size: 1em !important;
    	color: #fff;
    	font-weight: lighter;
    	font-style: normal !important;
    }
}

.calendar-widget-table{
	width: 100%;
	text-align: center;
}
.calendar-widget-table tbody .calendar-day {
    text-align: center !important;
    background-position: 5px 5px;
}
.calendar-widget-table tbody td {
	padding: 21px 2px !important;
}
.calendar-widget-table thead th {
    text-align: center !important;
    padding: 16px 5px !important;
    font-size: 1.6em !important;
}
.calendar-widget-table tbody .calendar-header td {
    padding: 9px 5px !important;
}
.calendar-widget-table tbody .today {
    font-weight: 700;
    color: #009e73;
}

.MW1200{
	    max-width: 1200px;
}
.FormContainer{
	position: fixed;
	display: none;
	top: 0px;
	left: 0px;
	width: 100%;
	z-index: 9999;
	height: 100%;
	padding: 20px;
	overflow: hidden;
	
	background-color: rgba(240, 242, 247, 0.9);
	#UserForm_Form{
		max-width: 500px;
		width: 100%;
		background-color: #ffffff;
	    box-shadow: 0px 1px 2px 0px #e0e2ea;
	    border: 1px solid #daddea;
	    padding: 20px;
	    @inlclude border-radius(3px);
	    #UserForm_Form_action_process{
	    	padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff;
		    border-radius: 3px;
		    background-color: #004d90;
		    font-weight: lighter;
		    margin-top: 10px;
		    box-shadow :inset 0px -2px 8px 0px #23a3d6, inset 0px 2px 8px 0px #8bc2d8, 0px 0px 3px 1px #daddea;
		   	-o-box-shadow :inset 0px -2px 8px 0px #23a3d6, inset 0px 2px 8px 0px #8bc2d8, 0px 0px 3px 1px #daddea;
		    -ms-box-shadow :inset 0px -2px 8px 0px #23a3d6, inset 0px 2px 8px 0px #8bc2d8, 0px 0px 3px 1px #daddea;
		    -moz-box-shadow :inset 0px -2px 8px 0px #23a3d6, inset 0px 2px 8px 0px #8bc2d8, 0px 0px 3px 1px #daddea;
		    -webkit-box-shadow :inset 0px -2px 8px 0px #23a3d6, inset 0px 2px 8px 0px #8bc2d8, 0px 0px 2px 1px #daddea;
	    }
	}
	.CloseForm{
		cursor: pointer;
	    position: absolute;
	    top: 10px;
	    right: 10px;
	    z-index: 9999;
	    font-size: 1.4em;
	    color: #fff;
	    background-color: #004d90;
	    width: 30px;
	    height: 30px;
	    text-align: center;
		*{color: #fff;}
	}
}
.translate{
	cursor: pointer;
}
.TranslateBG{
	display: none;
	position: fixed;
	top: 0px;
	z-index: 9999;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0, 0.7);
}
#google_translate_element{
	display: none;
	position: fixed; 
	z-index: 9999;
	top: 50%;
	left: 50%;
	@include transform($center);
}

#SearchResults{ 
	padding: 0px;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
	font-size: 0.8em;
	li{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
		list-style: none !important;
	}
}

.emailFooter {
    font-size: 1em !important;
    line-height: 1em !important;
    font-weight: lighter;
    display: inline-block;
    vertical-align: -1px;
}
.dn{
	display: none !important;
}

#Home3  .calendarLink,
.calendarLink,
#Home3 .SliderControls .next2,
.SliderControls .next{
	span{
		@include transition($t1);
	}
	&:hover *{
			color: #17adec !important;
		
	}
}

.flexslider .flex-direction-nav {
	position: absolute;
    top: 50%;
    left: 50%;
    width: 93%;
    z-index: 9000;
    height: 30px;
    @include transform(translate(-50%));
}

.hideIntro{
	#Intro{
		display: none !important;
	}
}


/* Alterations - Ash */
.bannerText{
	*{
		color: #fff !important;
	}
}
.sliderContent{
	background-color: #b2c9dd !important;
}
.bottomSection,
.innerDiv .slideOverlay{
	background-color: #004d90 !important;
}
.bottomSection{
	color: #fff !important;
	*{
		color: #fff !important;
	}
}

.currentChild{
	background-color: #e6e4e4 !important;
}

.hideIntro{
	#Intro{
		display: none;
	}
}

.OpenLevel{
	display: block !important;
	background-color: #eee;
}

.sliderContent .slideTitle,
.datePart2,
.datePart1{
	color: #131a50 !important;
}



#ExternalLinks{
	a{
		display: inline-block;
		padding: 5px;
		    height: 40px;
    display: inline-block;
    vertical-align: middle;
		*{
			color: #2f3b67;
			font-size: 1.3em;
		}
		path{
			fill: #2f3b67 !important;
		}
	}
}



.SAFHeaderLogo{
    position: absolute;
    display: block;
    z-index: 9;
    top: 197px;
    left: 42px;
    img{
    	display: inline-block;
    	width: 90px;
    	height: auto !important;
    	vertical-align: middle;
    	display: inline-block;
    }
    span{
	    display: inline-block;
	    color: #fff;
	    vertical-align: middle;
	    padding: 10px;
	    font-size: 1em;
	    width: 191px;
	    line-height: 1.3em;
	    @include text-shadow(0px 1px 2px #000);
	} 
}
@media (max-width: 880px){
	.SAFHeaderLogo {
		@include transform(scale(0.6));
	    right: -52px;
	    top: 31px;
	    left: auto;
	    width: 300px;
}
}  

.PageBanner{
	height: 60vh;
}

#content{
	img{
		height: auto !important;
	}
}
