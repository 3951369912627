/*
|===============================================================
|	Global Resetting
|===============================================================
*/
* {
	font-size: 1em;
	/* reset font-sizes to 1em == 10px */
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	list-style:none;
	outline: none !important;
}
	

/*
|===============================================================
|	Column Position
|===============================================================
*/
.col-1{min-width:8.33%;width: 8.33%;}
.col-2{min-width:16.66%;width: 16.66%;}
.col-3, .col-1-4{min-width:25%;width: 25%;}
.col-4, .col-1-3{min-width:33.33%;width: 33.33%;}
.col-5{min-width:41.66%;width: 41.66%;}
.col-6, .col-1-2 {min-width:50%;width: 50%; position: relative;}
.col-7{min-width:58.33%;width: 58.33%;}
.col-8{min-width:66.66%;width: 66.66%;}
.col-9{min-width:75%;width: 75%;}
.col-10{min-width:83.33%;width: 83.33%;}
.col-11{min-width:91.66%;width: 91.66%;}
.col-12, .col-2-2, .row{min-width:100%;width: 100%;}
.col-five, .col-1-5{width:20%;}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-five, .row {
	position: relative;
	float: left;
}

/*
|===============================================================
|	Offsets - Basically pushes elements by a percentage
|===============================================================
*/
.offset-12 { margin-left: 100%; }
.offset-11 { margin-left: 91.66666667%; }
.offset-10 { margin-left: 83.33333333%; }
.offset-9 { margin-left: 75%; }
.offset-8 { margin-left: 66.66666667%; }
.offset-7 { margin-left: 58.33333333%; }
.offset-6 { margin-left: 50%; }
.offset-5 { margin-left: 41.66666667%; }
.offset-4 { margin-left: 33.33333333%; }
.offset-3 { margin-left: 25%; }
.offset-2 { margin-left: 16.66666667%; }
.offset-1 { margin-left: 8.33333333%; }
.offset-0 { margin-left: 0%; }

/*
|===============================================================
|	Text Align
|===============================================================
*/
.tac{
	text-align: center;
}

.tar{
	text-align: right;
}

.clear{
	clear:both;
}

.db{
	display: block;
}

/*
|===============================================================
|	Inner Frame Class
|===============================================================
*/

.inner {
	width: 1400px;	
	margin: 0 auto;
	max-width: 100%;
	position: relative;
}

.posR{position: relative;}
.posA{position: absolute;}
.posF{position: fixed;}

/*
|===============================================================
|	Margin
|===============================================================
*/
.m10{margin: 10px;}
.m15{margin: 15px;}
.m20{margin: 20px;}
.m25{margin: 25px;}
.m30{margin: 30px;}
.m40{margin: 40px;}

.mt10{margin-top: 10px;}
.mt15{margin-top: 15px;}
.mt20{margin-top: 20px;}
.mt25{margin-top: 25px;}
.mt30{margin-top: 30px;}
.mt40{margin-top: 40px;}

.mb10{margin-bottom: 10px;}
.mb15{margin-bottom: 15px;}
.mb20{margin-bottom: 20px;}
.mb25{margin-bottom: 25px;}
.mb30{margin-bottom: 30px;}
.mb40{margin-bottom: 40px;}

.mr10{margin-right: 10px;}
.mr15{margin-right: 15px;}
.mr20{margin-right: 20px;}
.mr25{margin-right: 25px;}
.mr30{margin-right: 30px;}
.mr40{margin-right: 40px;}

.ml10{margin-left: 10px;}
.ml15{margin-left: 15px;}
.ml20{margin-left: 20px;}
.ml25{margin-left: 25px;}
.ml30{margin-left: 30px;}
.ml40{margin-left: 40px;}

.mAuto{
	margin: 0 auto !important;
}

/*
|===============================================================
|	Padding
|===============================================================
*/
.p5{padding: 5px;}
.p10{padding: 10px;}
.p15{padding: 15px;}
.p20{padding: 20px;}
.p25{padding: 25px;}
.p30{padding: 30px;}
.p40{padding: 40px;}

.pt67{padding-top: 67px;}
.pt50{padding-top: 50px;}
.pb60{padding-bottom: 60px;}

.pt5{padding-top: 5px;}
.pt10{padding-top: 10px;}
.pt15{padding-top: 15px;}
.pt20{padding-top: 20px;}
.pt25{padding-top: 25px;}
.pt30{padding-top: 30px;}
.pt40{padding-top: 40px;}

.pb5{padding-bottom: 5px;}
.pb10{padding-bottom: 10px;}
.pb15{padding-bottom: 15px;}
.pb20{padding-bottom: 20px;}
.pb25{padding-bottom: 25px;}
.pb30{padding-bottom: 30px;}
.pb40{padding-bottom: 40px;}
.pb50{padding-bottom: 50px;}

.pr5{padding-right: 5px;}
.pr10{padding-right: 10px;}
.pr15{padding-right: 15px;}
.pr20{padding-right: 20px;}
.pr25{padding-right: 25px;}
.pr30{padding-right: 30px;}
.pr40{padding-right: 40px;}

.pl5{padding-left: 5px;}
.pl10{padding-left: 10px;}
.pl15{padding-left: 15px;}
.pl20{padding-left: 20px;}
.pl25{padding-left: 25px;}
.pl30{padding-left: 30px;}
.pl40{padding-left: 40px;}

/*
|===============================================================
|	Hide Property
|===============================================================
*/
.hide {
  display: none !important;
}
.innerSmall {
    width: 1100px;
    margin: 0 auto;
    max-width: 100%;
    position: relative;
}
