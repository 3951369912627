/*** Mobile Navigation ***/
#MenuIcon{
    position: absolute;
    width: 30px;
    height: 28px;
    z-index: 6000;
    display: none;
    top: 37px;
    right: 30px;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid #fff;
    @include border-radius(3px);
    span{
      position: absolute;
      display: block;
      height: 3px;
      width: 81%;
      left: 50%;

    @include border-radius(3px);
      background-color: $textLight;
      @include transform(translateX(-50%) rotate(0deg));
      @include transition($t1);
    }
    &:hover span{
      background-color: $textLight;
    }
    p{
      font-size: 0.8em;
      color: $textLight;
      text-transform: uppercase;
      font-weight: lighter;
      position: absolute;
      left: 50%;
      @include transform(translate(-50%));
      bottom: 12px;
    }
    span:nth-child(1){
      top: 4px;
    }
    span:nth-child(2){
     top: 11px;
    }
    span:nth-child(3){
      top: 18px;
    }
    span:nth-child(4){
         top: 18px;
    }
}

.mobileNavBg {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  z-index: 6005;
  visibility: hidden;
  @include transition(all 1s);
  opacity: 0;
}

/*** Menu ***/
#MenuBg{
  position: absolute;
  width: 0%;
  height: 300%;
  left: 50%;
  top: 50%;
background-color: #2f3b67;
  @include transform(translate(-50%, -50%) rotate(-45deg));
  @include transition(all 1s ease-in-out);
  display: none !important;
}
#MobileNavigation{
  opacity: 0;
background-color: #2f3b67;
  @include transition(all 1s);
  position: fixed;
  z-index: 9999;
  top: 0px;
  left: -40%;
  height: 100%;
  overflow: hidden;
  width: 40%;
  .toggleNav{
    cursor: pointer;
    background-color: transparent;
    position: absolute;
    right: 0px;
    font-weight: lighter;
    top: 8px;
    width: 30px;
    height: 30px;
    color: #fff;
    font-size: 27px;
    border: none;
    outline: none;
  }
  .TopLevel{
    padding: 10px;
    position: absolute;
    display: inline-block;
    left: 50%;
    max-height: 70%;
    top: 50%;
    overflow-y: auto;
    @include border-radius(0px);
    @include transform($center);
    max-width: 300px;
    width: 100%;
    text-align: left;

    &:before{
      content: '';
      position: absolute;
      display: block;
      border-left: 1px solid #fff;
      border-top: 1px solid #fff;
      opacity: 0.6;
      height: 50px;
      width: 50px;
      top: -20px;
      left: -20px;
    }
  }
  ul{
    ul{
      display: none;
      background-color: rgba(0,0,0,0.05);
    }
    li{
      position: relative;
      a{
        color: #fff;
        text-align: left;
        font-size: 1em;
        width: 100%;
        display: block;
        font-weight: lighter;
        padding: 10px 34px 10px 20px;
        text-decoration: none !important;
        @include transition($t1);
        &:hover{
           background-color: rgba(0,0,0,0.1);
        }
      }
    }
  }
}

.menuBgDark{
  position: fixed;
  display: none;
  z-index: 9999;
  width: 100%;
  top: 0px;
  left: 0px;
  height: 100%;
  background-color: rgba(240, 242, 247, 0.9);
}
.ActiveMenu{

  #MobileNavigation{
    display: block;
    opacity: 1 !important;
    margin-top: 0px !important ;
    height: 100%;
    left: 0% !important;
  }
  .mobileNavBg {
    visibility: visible;
    opacity: 1;
  }
  #MenuBg{
    width: 200%;
    height: 300%;
  }
   #MenuIcon {
    /*   span:nth-child(1){
        top: 12px;
        @include transform(translateX(-50%)  rotate(45deg));
      }
      span:nth-child(3){
        @include transform(translateX(-50%)  rotate(-45deg));
        top: 12px;
      }
      span:nth-child(2){
        margin-left: 100px !important;
        opacity: 0 !important;
        width: 50%;
      }
      span:nth-child(4){
        opacity: 0 !important;
      } */
  }
}

.close{
  z-index: 500;
}
.close,
.closeMenu{
  position: absolute;
  top: 30px;
  left: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
/*  background-color: #b3bdc1;*/
    border-radius: 3px;
}
.closeMenu{
  top: 31px;
    z-index: 300;
}
.close span,
.closeMenu span{
  position: absolute;
  content:'';
  display: block;
  width: 100%;
  height: 3px;
  background-color: #fff;
  width: 80%;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  transition: all 0.35s;
  -o-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -webkit-transition: all 0.35s;
}
.close span:nth-child(1),
.closeMenu span:nth-child(1){
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -o-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}

.close span:nth-child(2),
.closeMenu span:nth-child(2){
  transform:translateX(-50%) translateY(-50%) rotate(45deg);
  -o-transform:translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform:translateX(-50%) translateY(-50%) rotate(45deg);
  -moz-transform:translateX(-50%) translateY(-50%) rotate(45deg);
  -webkit-transform:translateX(-50%) translateY(-50%) rotate(45deg);
}

.close:hover span:nth-child(1),
.closeMenu:hover span:nth-child(1){
  transform:translateX(-50%) translateY(-50%) rotate(45deg);
  -o-transform:translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform:translateX(-50%) translateY(-50%) rotate(45deg);
  -moz-transform:translateX(-50%) translateY(-50%) rotate(45deg);
  -webkit-transform:translateX(-50%) translateY(-50%) rotate(45deg);
}
.close:hover span:nth-child(2),
.closeMenu:hover span:nth-child(2){
  transform:translateX(-50%) translateY(-50%) rotate(-45deg);
  -o-transform:translateX(-50%) translateY(-50%) rotate(-45deg);
  -ms-transform:translateX(-50%) translateY(-50%) rotate(-45deg);
  -moz-transform:translateX(-50%) translateY(-50%) rotate(-45deg);
  -webkit-transform:translateX(-50%) translateY(-50%) rotate(-45deg);
}

/*** Scroll Bar ***/
.TopLevel::-webkit-scrollbar {
    width: 3px;
    background-color: #efefef !important;
}
 
.TopLevel::-webkit-scrollbar-track {
background-color: #efefef !important;
}
.TopLevel::-webkit-scrollbar-track-piece{
  background-color:#efefef;
  @include border-radius(0px);
}
 
.TopLevel::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #6b8392;
}