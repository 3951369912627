
@media all and (min-width: 1501px) and (max-height: 1020px) {
	.welcome-fly2,
	.welcome-fly1{
		display: none;
	}
}
@media all and (max-width: 1500px)  {
	.welcomeContainer {
	    width: 60%;
	}
}

@media all and (max-width: 1257px)  {
	.fly3 {
	    top: -137px !important;
	    right: 2% !important;
	}
}
@media all and (min-width: 1201px)  {
	#MobileNavigation{
		display: none !important;
	}

	.menuBgDark{
		display: none !important;
	}


}
@media all and (max-width: 1200px)  {
	.MenuButton{
		top: 0px !important;
		opacity: 1 !important;
	}
	.welcomeContainer {
	    width: 80%;
	}
	.topBar,
	.MenuContainer{
		display: none !important;
	}
	.ActiveMenu{
  		overflow: hidden !important;
	}
}
@media all and (max-width: 1100px)  {
	/*#Home1 .col-five {
		width: 33.33% !important;
		padding-bottom: 5px !important;
	}*/
	.LinkboxPadding {
	    padding: 0px 0px 0px 0px !important;
	}

	#Home1 .col-five .center{
		padding-bottom: 0px !important;
	}
	#Home1 .col-five .center .hover {
	    position: relative;
    	float: left;
	}
	#Home1 {
		z-index: 8888;
		padding: 0px !important;
		position: fixed;
		bottom: 0px;
		.bg{
			background: none !important;
		}
	}
	#Home1 .col-five {
	    opacity: 1 !important;
	    -webkit-transform: scale(1) !important;
	    -moz-transform: scale(1) !important;
	    -ms-transform: scale(1) !important;
	    transform: scale(1) !important;
	}
	.HomePage #Home1 .LinkboxPadding{
		padding: 0px !important;
	}
	#Home1 .col-five .center:hover .bg{
		-webkit-transform: scale(1) !important;
	    -moz-transform: scale(1) !important;
	    -ms-transform: scale(1) !important;
	    transform: scale(1) !important;
	}

	#Home1 img{
		position: absolute;
		top: 10px !important;
		left: 50%;
		@include transform(translateX(-50%) !important);
		height: 20px !important;
		right: 0px !important;
		width: auto !important;
	}
	#Home1 .col-five .center .hover{
		padding: 10px !important;
	}
	#Home1 span{
		padding-right: 0px !important;
		padding-top: 40px;
		display: block !important;
		width: 100%;
		text-align: center;
		font-size: 0.7em !important;
	}
	#Home1 .col-five .center .bg-1 {
	    background-color: rgb(7,76,135);
	}
	#Home1 .col-five .center .bg-2, #Home1 .col-five .center .bg-4 {
	    background-color: rgb(0,77,144);
	}
	#Home1 .col-five .center .bg-3, #Home1 .col-five .center .bg-0 {
	    background-color: rgb(40,36,67);
	}
/*	#Home1 .col-five.box-4,
	#Home1 .col-five.box-5{
		width: 50% !important;
	} 
	.innerPageLinks .col-five.box-4,
	.innerPageLinks .col-five.box-5{
		padding-top: 5px !important;
	}*/
}
@media all and (max-width: 991px)  {
	.hidden-sm {
		display: none !important;
	}
	.welcomeContainer {
	    width: 100%;
	}
}



@media all and (max-width: 1024px)  {
	#Home3 {
	    background-attachment: initial;
	    -webkit-background-attachment: initial;
	}
	.banner .bg {
    	margin-top: 0px !important;
	}
	.bannerInfo{
 		margin-bottom: 0px !important;
	}
}
@media all and (max-width: 960px)  {
	.sideBarContainer{
		display: none !important;
	}
	.clearMid{width: 100%;clear: both;}
	
}
@media all and (max-width: 900px)  {
	#Home2{
		.calendarLeft,
		.col-6.CenterItems{
			width: 100% !important;
			clear: both !important;
			padding-left: 0px !important;
			padding-right: 0px !important;
		}
		.flex.ClearFlex{
			display: block !important;
		}
	}
}
@media all and (max-height: 900px)  {
	.welcome-fly2,
	.welcome-fly1{
		display: none;
	}

}
@media all and (max-width: 880px)  {

	.mainLogo{
		opacity: 1 !important;
	}
	.mainLogo1{
		opacity: 0 !important;
	}
	#InrtoFly{
		display: none;
	}

	.socialClear{
		clear: both;
		width: 100% !important;
		padding: 0px !important;
		padding-top: 30px !important;
	}
	#Home4 {
	    padding: 30px 0px 80px 0px !important;
	    .fly3{
	    	display: none !important;
	    }
	}
	.bannerText {
   	 font-size: 2em;
	}
	#Logo {
	    top: 20px;
	    left: 20px;
	    max-width: 150px;
	}
	#MobileNavigation {
		left: -100%;
	    width: 100% !important;
	}

	.ActiveQuickLinks #QuickLinkContainer {
		width: 100%;
	}
	.ActiveQuickLinks .PageContainer, .ActiveQuickLinks .banner {
	    min-width: 100% !important;
	    width: 100% !important;
	}
	.closeQuicklinks{
		display: block;
	}
	.ActiveQuickLinks{
		overflow: hidden !important;
	}
}
@media all and (max-width: 768px)  {
	.clearSmall{
		clear: both;
		width: 100% !important;
	}

	.tacMid{
		text-align: center !important;
	}
	.staffProfile{
		width: 50%;
		display: block;
    	float: left;
	}
}

@media all and (max-width: 740px)  {
	
	.pageTitle {
	    font-size: 3em !important;
	}

	footer{
		.flex{
			display: block !important;
		}
		.col-6{
			width: 100% !important;
			clear: both;
			text-align: center;
		}
		.slink{
			display: none !important;
		}
		 .bottomFooter {
		    position: relative;
		    bottom: auto;
		    display: block;
		    width: 100%;
		    text-align: center;
		    .fse{
		    	width: 100%;
		    	text-align: center !important;
		    }
		}

	}

	.bannerInfo {
	    bottom: 30px !important;
	}
	.bannerText {
	    padding: 0px 42px;
	}
}
@media all and (max-width: 600px)  {
	
	.welcomeContainer.CenterItems{
		display: block !important;
		padding-bottom: 20px;
	}
	.welcomeContainer .welcomeInner .Title {
    	font-size: 1.5em !important;
	}

	#Home2 {
	    padding: 180px 20px 40px 20px;
	}
	.newsText{
		width: 100% !important;
	}
	.newsImage{
		display: none !important;
	}
	.grid-item, .grid-sizer { 
		width: 50%; 
	}
}
@media all and (max-height: 620px)  {
	    .ValueWord{
	    	left: 50px;
	    	font-size: 2em;
	    }
}
@media all and (max-height: 580px)  {
	.sideLinks {
		display: none !important;
	}
}
@media all and (max-width: 555px)  {
	.noticesContainer{
	   height: 100%;
	   width: 100%;
	   left: 0px !important;
	   top: 0px !important;
	   @include transform(translate(0px, 0px));
	   border: none !important;
	   @include border-radius(0px !important);
	}
	.staffProfile{
		width: 100%;
		display: block;
    	float: left;
	}
}
@media all and (max-width: 500px)  {

	.calendar-widget-table thead th a {
	    font-size: 0.7em !important;
	}
	.bannerInfo img{
		display: none;
	}
	#Logo {
	    top: 10px;
	    left: 10px;
	    max-width: 100px;
	}
	.QuickLinksToggle {
		right: -62px !important;
	    @include transform(rotate(90deg) scale(0.7) !important);
	}
	.welcomeToggle{
		left: -103px !important;
		 @include transform(rotate(-90deg) scale(0.7) !important);
	}
	.pageTitle {
	    font-size: 2em !important;
	}
	.bannerText{
		display: none !important; 
	}

	.ValueWord {
	    font-size: 2em;
	}
}
@media all and (max-width: 450px)  {
	#Home1 span {
	    font-size: 0.5em !important;
	}
	

	.bannerText {
   	 font-size: 1.6em;
	}

	.tacSmall{
		text-align: center !important;
	}
	.hiddenSmall {
		display: none !important;
	}

	.grid-item, .grid-sizer { 
		width: 100%; 
	}
}

/*** iPad Landscape ***/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
	.calendarLeft {
	    padding-right: 4%;
	}
	
	footer .flex > .col-6:first-of-type{
		width: 70% !important;
	}
	footer .flex > .col-6:last-of-type{
		width: 30% !important;
		min-height: 156px !important;
		min-width: 30% !important;
	}

	.fly3 {
	    top: -137px !important;
	    right: 2% !important;
	}
	.quicklink{
		min-height: 0px !important;
	}
	
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) { 
	.welcomeContainer .welcomeInner .Title {
	    margin-bottom: 20px !important;
	}
	footer .flex > .col-6:first-of-type{
		width: 100% !important;
		clear: both !important;
	}
	footer .flex > .col-6:last-of-type{
		width: 100% !important;
		clear: both !important;
	}
	footer .slink{
		display: none !important;
	}
	footer .flex{
		display: block !important;
	}
	footer .bottomFooter{
		position: relative !important;
	}
/* 
	.fse{
		font-size: 0.7em !important;
		*{
			font-size: 1em !important;
		}
	} */
}
